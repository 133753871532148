.home {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 1200px;
    align-items: center;
    margin: 0 auto;

    .homeContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: #333;
        margin: 20px 0 0 0; 
        font-size: 18px;     
    }

    .carouselContainer {
        display: flex;
        flex-direction: row;
        height: 1000px;
        width: 850px;
        border-radius: 4px;
        overflow: hidden;
        margin: 20px 0 0 0;
        justify-content: center;

        .carousel {
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            height: 100%;
            width: 100%;
            //object-fit:fill;

            // .carousel-caption {
            //     bottom: 50.00rem;
            // }
        }
    }
}