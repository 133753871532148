.contact {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    align-items: center;


    .contactContent {
        display: flex;
        flex-direction: row;
        color: #333;
        margin: 20px 0 0 0;
        justify-content: center;
        text-align: center;
        font-size: 18px;
    }
}