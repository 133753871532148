.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100px;
    justify-content: flex-end;

    .navbarItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        background-color: white;
        border: 1px solid #4D1D29;
        color: #4D1D29;
        font-weight: 600;
        margin: 0 0 0 20px;
        font-family: "Montserrat Semi-bold", sans-serif;

        &:hover {
            background-color: #4D1D29;
            border: 1px solid #4D1D29;
            color: white;
            cursor: pointer;
        }
    }
}