.pageTitle {
    display: flex;
    flex-direction: row; 
    margin: 20px 0 0 0;

    .pageTitleLabel {
        font-size: 20px;
        font-family: "Montserrat Semi-bold", sans-serif;
        color: #4D1D29;
    }

   
}