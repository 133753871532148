.page {
    display: flex;
    flex-direction: column;
    font-family:'Open Sans', sans-serif;

    .pageHeader {
        display: flex;
        flex-direction: row;
        padding: 0 40px;
        height: 100px;
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgba(0 0 0 / 0.15);
        overflow: hidden;
    }

    .pageContent {
        display: flex;
        flex-direction: row;
        // height: 70vh;
        padding: 20px 0;
        margin: 10px 40px;
        background-color: white;
        border-radius: 4px;
    }

    .pageFooter {
        display: flex;
        flex-direction: row;
        padding: 20px;
        height: 20vh;
    }
}